import {
  SEND_SMS_VERIFICATION_OTP,
  VERIFY_PHONE,
} from "@/graphql/queries/phoneVerification";
import { apolloClient } from "@/plugins/apollo";

const phoneVerifApi = {
  async sendSmsOtp(phone) {
    if (!phone) throw new Error("No phone provided!");

    const { data } = await apolloClient.query({
      query: SEND_SMS_VERIFICATION_OTP,
      variables: {
        phone,
      },
      fetchPolicy: "network-only",
    });

    return data.sendSmsVerificationOtp;
  },

  async verify(phone, otp) {
    if (!phone) throw new Error("No phone provided!");
    if (!otp) throw new Error("No code provided!");

    const { data } = await apolloClient.query({
      query: VERIFY_PHONE,
      variables: {
        phone,
        otp,
      },
      fetchPolicy: "network-only",
    });

    return data.verifyPhone;
  },
};

export default phoneVerifApi;

import gql from "graphql-tag";

export const VERIFY_PHONE = gql`
  query VerifyPhone($phone: String!, $otp: String!) {
    verifyPhone(phone: $phone, otp: $otp) {
      isVerified
    }
  }
`;

export const SEND_SMS_VERIFICATION_OTP = gql`
  query Query($phone: String!) {
    sendSmsVerificationOtp(phone: $phone) {
      isSmsSent
    }
  }
`;

<template>
  <div class="block-tel">
    <div class="inputs block-tel__inputs">
      <InputTextTelephoneValidation
        v-model="data.phone"
        @update:is-valid-tel="isValidPhoneInput = $event"
      />
      <InputTextTelephoneCodeRegistration
        v-if="isSmsSent"
        v-model:otp="data.otpInput"
        :otpVerified="otpVerified"
      />
      <ButtonBasic
        v-if="!isSmsSent"
        @click="handleOtpSending(data.phone)"
        :label="'Wyślij kod'"
        :isDisabled="!canSendSms || isVerified"
        :size="'small'"
        class="button inputs__button"
      />
    </div>
    <transition name="block-tel-messages">
      <div v-if="isSmsSent || message" class="block-tel-messages">
        <transition-group>
          <p
            v-if="message"
            v-html="message"
            class="message block-tel-messages__message"
          ></p>
          <ButtonLink
            v-else-if="canSendSms"
            @click="handleOtpSending(data.phone)"
            :label="'Wyślij kod ponownie'"
            :size="'small'"
          />
        </transition-group>
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";

import InputTextTelephoneValidation from "@/components/UI/InputTextTelephoneValidation.vue";
import InputTextTelephoneCodeRegistration from "@/components/UI/InputTextTelephoneCodeRegistration.vue";
import ButtonLink from "./UI/Base/ButtonLink.vue";
import ButtonBasic from "./UI/Base/ButtonBasic.vue";
import { watch } from "vue";
import usePhoneVerification from "@/composables/usePhoneVerification";

export default {
  components: {
    InputTextTelephoneValidation,
    InputTextTelephoneCodeRegistration,
    ButtonLink,
    ButtonBasic,
  },

  props: {
    phone: String,
  },

  emits: ["update:phone", "update:isVerified"],

  setup(props, { emit }) {
    const {
      sendSmsOtp,
      verifyPhone,
      isSmsSent,
      phoneVerified,
      otpVerified,
      timeBeforeNextSms,
      isValidPhoneInput,
      canSendSms,
      verifAttempts,
    } = usePhoneVerification(props.phone);
    const data = reactive({
      phone: props.phone,
      otpInput: "",
    });
    const message = computed(() => {
      if (verifAttempts.value === 0) {
        return `Próby się skończyły.<br />
            Spróbuj za kilka godzin!`;
      }
      if (timeBeforeNextSms.value === 0) {
        return null;
      }
      return `Kod został wysłany.<br />
            Kolejna sproba dostępna po ${timeBeforeNextSms.value / 1000} sek`;
    });

    const isVerified = computed(
      () =>
        data.phone === phoneVerified.value &&
        data.otpInput === otpVerified.value
    );

    watch(
      () => props.phone,
      (newPhone) => {
        data.phone = newPhone;
      },
      { immediate: true }
    );
    // watch(
    // TODO: refactor and create a normal 2-way binding for masked text inputs, then this watcher will work
    //   () => data.phone,
    //   () => {
    //     data.otpInput = "";
    //   },
    //   { immediate: true }
    // );

    watch(
      isVerified,
      (isVerified) => {
        emit("update:isVerified", isVerified);
      },
      { immediate: true }
    );

    watch(
      () => data.otpInput,
      (otpInput) => {
        const isCompleteOtpInput =
          otpInput.length === 6 && !otpInput.includes("_");
        if (!isCompleteOtpInput) return;
        handlePhoneVerification(data.phone, otpInput);
      }
    );

    const handleOtpSending = async (phone) => {
      try {
        sendSmsOtp(phone);
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    const handlePhoneVerification = async (phone, otpInput) => {
      try {
        const isVerified = await verifyPhone(phone, otpInput);
        if (!isVerified) return;
        emit("update:phone", phone);
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    return {
      data,
      canSendSms,
      handleOtpSending,
      message,
      isValidPhoneInput,
      isSmsSent,
      otpVerified,
      isVerified,
      verifAttempts,
    };
  },
};
</script>

<style scoped>
.block-tel {
  width: 380px;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 25px;
}
.block-tel-messages {
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.block-tel-messages__message {
  width: 100%;
  text-align: center;
}
.block-tel__inputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 40px;
}
.block-tel__inputs :deep(.inputs__button) {
  width: 170px;
}

@media (max-width: 1200px) {
  .block-tel {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 25px;
  }
  .block-tel-messages {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .block-tel-messages__message {
    width: 100%;
    text-align: center;
  }
  .block-tel__inputs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    column-gap: unset;
  }
  .block-tel__inputs :deep(.inputs__button) {
    width: 167px;
  }
}

/* Transitions  */
.block-tel-messages-enter-active {
  transition: height 0.4s;
}
.block-tel-messages-enter-from {
  height: 0px;
}
.v-enter-active {
  transition: all 0.4s;
  position: absolute;
}
.v-leave-active {
  transition: all 0.2s;
}
.v-enter-from {
  opacity: 0;
  transform: translateY(20px);
}
.v-leave-to {
  opacity: 0;
}
</style>

<template>
  <InputTextTextarea
    v-model="result"
    @focus="isError = false"
    @blur="checkError()"
    :name="name"
    :label="label"
    :rows="2"
    :maxlength="maxlength"
    :placeholder="placeholder"
    :error="isError"
    class="wide"
  />
</template>

<script>
import InputTextTextarea from "@/components/UI/Base/InputTextTextarea.vue";

import { computed, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

export default {
  components: { InputTextTextarea },

  props: {
    modelValue: String,
    name: {
      type: String,
      required: true,
    },
    label: String,
    maxlength: {
      type: Number,
      default: 65,
    },
    placeholder: String,
    validation: {
      type: Boolean,
      default: true,
    },
  },

  emits: ["update:isValidResult", "update:modelValue"],

  setup(props, { emit }) {
    const result = ref(props.modelValue);

    // Two-way binding
    watch(result, (newResult) => {
      emit("update:modelValue", newResult);
    });

    watch(
      () => props.modelValue,
      (newResult) => (result.value = newResult)
    );

    // Check error
    const isError = ref(false);

    // Validation for emptiness
    const isValidResult = computed(() => {
      if (props.validation === false) {
        return true;
      }
      return !!result.value;
    });

    watch(
      isValidResult,
      (newIsValid) => {
        emit("update:isValidResult", newIsValid);
      },
      { immediate: true }
    );

    const checkError = () => {
      isError.value = !isValidResult.value;
    };

    return {
      result,
      isError,
      checkError,
      isValidResult,
    };
  },
};
</script>

<style scoped>
.text-input--long.wide {
  width: 100%;
}
</style>

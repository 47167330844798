<template>
  <InputTextTelephoneCode
    v-model="data.otp"
    @focus="onFocus()"
    @blur="onBlur()"
    :error="isError"
    :success="isSuccess"
  />
</template>

<script>
import InputTextTelephoneCode from "@/components/UI/InputTextTelephoneCode.vue";
import { computed, reactive } from "@vue/reactivity";
import { watch } from "vue";

export default {
  components: { InputTextTelephoneCode },

  props: {
    otp: String,
    otpVerified: String,
  },

  emits: ["update:otp"],

  setup(props, { emit }) {
    const data = reactive({
      otp: props.otp,
      isTouched: false,
      isFocused: false,
    });

    const isSuccess = computed(() => {
      return props.otpVerified.length === 6 && props.otpVerified === data.otp;
    });
    const isComplete = computed(() => {
      return data.otp.length === 6 && !data.otp.includes("_");
    });
    const isError = computed(() => {
      if (data.isFocused && !isComplete.value) return false;
      return data.isTouched && !isSuccess.value;
    });

    watch(
      () => props.otp,
      (newOtp) => {
        data.otp = newOtp;
      }
    );
    watch(
      () => data.otp,
      (newOtp) => {
        emit("update:otp", newOtp);
      }
    );

    const onFocus = () => {
      data.isFocused = true;
      data.isTouched = true;
    };

    const onBlur = () => {
      data.isFocused = false;
    };

    return {
      data,
      onFocus,
      onBlur,
      isError,
      isSuccess,
    };
  },
};
</script>

<style>
/* For styles loook into InputText < style > block */
</style>

import phoneVerifApi from "@/api/phoneVerification";
import lStorageService from "./localStorageService";

const SMS_LEFT_LS_KEY = "SMS_OTP_LEFT";
const SMS_LEFT_LS_TTL_HOURS = 3;
const SMS_LEFT_INITIAL = 5;

const phoneVerificationService = {
  async sendSmsOtp(phone) {
    const phoneWithoutSpaces = this._preparePhone(phone);
    this.useVerifAttempt();
    const { isSmsSent } = await phoneVerifApi.sendSmsOtp(phoneWithoutSpaces);
    return isSmsSent;
  },

  async verify(phone, otp) {
    const phoneWithoutSpaces = this._preparePhone(phone);
    const { isVerified } = await phoneVerifApi.verify(phoneWithoutSpaces, otp);
    return isVerified;
  },

  getVerifAttempts() {
    let verifAttemptsLeft = lStorageService.getWithExpiry(SMS_LEFT_LS_KEY);
    console.log({ verifAttemptsLeft });
    if (verifAttemptsLeft === null) {
      this._resetVerifAttempts();
      verifAttemptsLeft = lStorageService.getWithExpiry(SMS_LEFT_LS_KEY);
    }
    return verifAttemptsLeft;
  },

  useVerifAttempt() {
    const verifAttempts = this.getVerifAttempts();
    if (verifAttempts === 0) {
      throw new Error(
        "No sms verification attempts left! Please try again later."
      );
    }
    lStorageService.updateWithTheSameExpiry(SMS_LEFT_LS_KEY, verifAttempts - 1);
  },

  _resetVerifAttempts() {
    const ttl = SMS_LEFT_LS_TTL_HOURS * 36e5;
    lStorageService.setWithExpiry(SMS_LEFT_LS_KEY, SMS_LEFT_INITIAL, ttl);
  },

  _preparePhone(phone) {
    return phone.trim().split(" ").join("");
  },
};

export default phoneVerificationService;

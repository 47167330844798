<template>
  <InputTextTelephone
    :model-value="modelValue"
    @update:model-value="
      $emit('update:modelValue', $event);
      tel = $event;
    "
    @focus="isErrorTel = false"
    @blur="checkError"
    @input="$emit('update:isValidTel', isValidTel)"
    :error="isErrorTel"
  />
</template>

<script>
import InputTextTelephone from "@/components/UI/InputTextTelephone.vue";

import { ref, computed } from "@vue/reactivity";

export default {
  components: { InputTextTelephone },

  props: {
    modelValue: String,
  },

  emits: ["update:modelValue", "update:isValidTel"],

  setup(props) {
    const tel = ref(props.modelValue);

    // Check if the length of tel is more than 7 chars (minimal possible)
    const isValidTel = computed(() => {
      return tel.value.length >= 7;
    });

    const isErrorTel = ref(false);
    const checkError = () => {
      isErrorTel.value = !isValidTel.value;
    };

    return {
      tel,
      isErrorTel,
      checkError,
      isValidTel,
    };
  },
};
</script>

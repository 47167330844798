<template>
  <div class="container">
    <div class="section container__section container__section--fee">
      <div class="block container__block--h1">
        <BaseH1 :text="'Wynagrodzenie'" />
        <TextDescription :text="'Napisz ile chcesz płacić nianię'" />
      </div>

      <div class="block-fee">
        <InputTextFeeHour
          v-model="offer.fee.hour.valueString"
          @update:is-valid-fee="offer.fee.hour.isValid = $event"
          :prefix="'Do'"
          :placeholder="'30'"
        />
        <ButtonLink
          v-if="!offer.fee.month.isShown"
          :label="'Dodaj opłatę za miesiąc'"
          class="show-fee-month-link block-fee__show-fee-month-link"
          @click="offer.fee.month.isShown = true"
        />
        <InputTextFeeMonth
          v-if="offer.fee.month.isShown"
          v-model="offer.fee.month.valueString"
          :prefix="'Do'"
          :placeholder="'2000'"
        />
      </div>
    </div>

    <div class="section container__section container__section--description">
      <div class="block container__block--h1">
        <BaseH1 :text="'Szczególy ogłoszenia'" />
      </div>
      <div class="block container__block--textarea-inputs">
        <InputTextTextarea2RowsWide
          v-model="offer.title.value"
          @update:is-valid-result="offer.title.isValid = $event"
          :name="'title'"
          :label="'Tytuł ogłoszenia'"
          :maxlength="85"
          :placeholder="'OFFER TITLE PLACEHOLDER'"
        />
        <InputTextTextarea6RowsWide
          v-model="offer.description.value"
          @update:is-valid-result="offer.description.isValid = $event"
          :name="'description'"
          :label="'Opis'"
          :maxlength="4000"
          :placeholder="'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus maximus, nulla at pretium iaculis, nunc ante auctor tellus, quis cursus aug. '"
        />
      </div>
    </div>

    <div class="section container__section container__section--tel">
      <div class="block container__block--h1">
        <BaseH1 :text="'Chcesz pozwolić nianiam dzwonić do Ciebie?'" />
      </div>
      <ButtonTertiary
        @click="offer.tel.isShown ? hideTel() : showTelInput()"
        :label="offer.tel.isShown ? 'Usunąć numer' : 'Dodać numer'"
      />
      <BlockTelInput
        v-if="offer.tel.isShown"
        v-model:phone="offer.tel.value"
        @update:is-verified="offer.tel.isValid = $event"
      />
      <TextHint
        :text="'W każdym razie, będziesz mogła uzyskać zgłoszenia od niań i zadzwonić do nich później'"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import InputTextFeeHour from "@/components/UI/InputTextFeeHour.vue";
import InputTextFeeMonth from "@/components/UI/InputTextFeeMonth.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";
import InputTextTextarea2RowsWide from "@/components/UI/InputTextTextarea2RowsWide.vue";
import InputTextTextarea6RowsWide from "@/components/UI/InputTextTextarea6RowsWide.vue";
import TextHint from "@/components/UI/TextHint.vue";
import ButtonTertiary from "@/components/UI/Base/ButtonTertiary.vue";
import BlockTelInput from "@/components/BlockTelInput.vue";

export default {
  components: {
    BaseH1,
    TextDescription,
    InputTextFeeHour,
    InputTextFeeMonth,
    ButtonLink,
    InputTextTextarea2RowsWide,
    InputTextTextarea6RowsWide,
    TextHint,
    ButtonTertiary,
    BlockTelInput,
  },

  props: {
    offerTitle: String,
    offerDescription: String,
    feeHour: Number,
    feeMonth: Number,
    offerTel: String,
  },

  emits: [
    "update:offerTitle",
    "update:offerDescription",
    "update:feeHour",
    "update:feeMonth",
    "update:offerTel",
    "update:isValid",
  ],

  setup(props, { emit }) {
    const offer = reactive({
      title: {
        value: props.offerTitle,
        isValid: false,
      },
      description: {
        value: props.offerDescription,
        isValid: false,
      },
      fee: {
        hour: {
          valueString: "",
          isValid: false,
        },
        month: {
          valueString: "",
          isShown: !!props.feeMonth,
        },
      },
      tel: {
        value: props.offerTel,
        isValid: false,
        isShown: false,
      },
    });

    const showTelInput = () => {
      offer.tel.isShown = true;
    };

    const hideTel = () => {
      offer.tel.isShown = false;
      offer.tel.value = "";
    };

    // Way out binding
    watch(
      () => offer.title.value,
      (newTitle) => emit("update:offerTitle", newTitle)
    );
    watch(
      () => offer.description.value,
      (newDesc) => emit("update:offerDescription", newDesc)
    );
    watch(
      () => offer.fee.hour.valueString,
      (newFeeHour) => {
        const newFeeHourNumber = Number(newFeeHour);

        if (newFeeHourNumber === props.feeHour) return;

        emit("update:feeHour", newFeeHourNumber);
      }
    );
    watch(
      () => offer.fee.month.valueString,
      (newFeeMonth) => {
        const newFeeMonthNumber = Number(newFeeMonth);

        if (newFeeMonthNumber === props.feeMonth) return;

        if (newFeeMonthNumber === 0) {
          emit("update:feeMonth", null);
          return;
        }

        emit("update:feeMonth", newFeeMonthNumber);
      }
    );
    watch(
      () => offer.tel.value,
      (newTel) => emit("update:offerTel", newTel)
    );

    // Way in binding
    watch(
      () => props.offerTitle,
      (newTitle) => (offer.title.value = newTitle)
    );
    watch(
      () => props.offerDescription,
      (newDesc) => (offer.description.value = newDesc)
    );
    watch(
      () => props.feeHour,
      (newFeeHour) => {
        if (newFeeHour === 0) return;

        const newFeeHourString = newFeeHour.toString();
        offer.fee.hour.valueString = newFeeHourString;
      },
      { immediate: true }
    );
    watch(
      () => props.feeMonth,
      (newFeeMonth) => {
        if (newFeeMonth === 0 || newFeeMonth === null) return;

        const newFeeMonthNumber = newFeeMonth.toString();
        offer.fee.month.valueString = newFeeMonthNumber;
      },
      { immediate: true }
    );
    watch(
      () => props.offerTel,
      (newTel) => {
        offer.tel.value = newTel;
        if (newTel && offer.tel.isShown === false) {
          showTelInput();
        }
      }
    );

    const isValid = computed(() => {
      return (
        offer.title.isValid &&
        offer.description.isValid &&
        offer.fee.hour.isValid &&
        (offer.tel.isShown === false || offer.tel.isValid)
      );
    });

    watch(isValid, (newIsValid) => emit("update:isValid", newIsValid), {
      immediate: true,
    });

    return {
      offer,
      isValid,
      showTelInput,
      hideTel,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 120px;
}
.container__section {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 70px;
}
.container__block--h1 {
  width: 360px;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;
}
.block-fee {
  display: flex;
  column-gap: 85px;
}
.block-fee__show-fee-month-link {
  margin-left: -48px;
}

.container__block--textarea-inputs {
  width: 560px;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 40px;
}

@media (max-width: 1200px) {
  .container__block--h1 {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 20px;
  }
  .block-fee {
    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 40px;
  }
  .block-fee__show-fee-month-link {
    margin-left: 0;
  }
  .container__block--textarea-inputs {
    width: 345px;
    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 40px;
  }
}
</style>
